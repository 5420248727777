// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import UserAgent from './user-agent';

export default {
  newUserAgent: (transport, appVersion) => {
    return new UserAgent(transport, { cacheDir: "cryptobox-cache", deviceInfo: { appVersion } });
  },

  // The device states.
  // See DeviceRecord.
  DeviceState: {
    // Ready to use.
    Unlocked: "unlocked",
    // Locked. The device cannot access the platform. It may be unlocked.
    Locked: "locked",
    // Revoked. The device won't be able to access the platform anymore.
    Revoked: "revoked",
  },

  // The key types for CryptoModule's ExportPublicKeyToDER().
  KeyType: {
    // RSA key.
    RSA: "rsa",
    // ECDSA key.
    ECDSA: "ecdsa",
  },

  // The error codes.
  ErrorCode: {
    // The user agent has encountered an internal error. This is not fatal and the client may proceed.
    InternalError: "internalError",
    // The operation has been canceled.
    Canceled: "canceled",
    // The resource does not exist.
    NotFound: "notFound",
    // The resource is no longer available.
    Gone: "gone",
    // Operation not permitted.
    InvalidOperation: "invalidOperation",
    // Invalid argument.
    InvalidParameter: "invalidParameter",
    // The user agent has received data from the server that do not pass the security checks.
    SecurityError: "securityError",
    // Invalid acryonym.
    InvalidAcronym: "invalidAcronym",
    // The certificate cannot be parsed.
    InvalidCertificate: "invalidCertificate",
    // The user credentials are invalid.
    InvalidCredentials: "invalidCredentials",
    // Invalid email.
    InvalidEmail: "invalidEmail",
    // The instant message is too long or it has too many attachments. See ValidationModule's Limits().
    InvalidMessage: "invalidMessage",
    // Invalid name.
    InvalidName: "invalidName",
    // File or directory already exists.
    Exist: "exist",
    // Is a directory.
    IsDirectory: "isDirectory",
    // Path does not exist.
    NotExist: "notExist",
    // Not a directory.
    NotDirectory: "notDirectory",
    // Disk full.
    DiskFull: "diskFull",
    // The network connection has failed. This may be a configuration issue with the client, the network or the server.
    NetworkError: "networkError",
    // The server has returned an HTTP error that were not handled otherwise.
    ServerError: "serverError",
    // A user with the same email is already registered.
    AlreadyRegistered: "alreadyRegistered",
    // The given validation token is invalid or has expired.
    BadToken: "badToken",
    // Another user is updating the resource. The client may reissue the operation at a later time.
    Conflict: "conflict",
    // The email is not allowed for account registration.
    EmailNotAllowed: "emailNotAllowed",
    // The user does not have the rights to complete the operation.
    Forbidden: "forbidden",
    // The server license is invalid.
    InvalidLicense: "invalidLicense",
    // No more user licenses are available.
    NoLicenseAvailable: "noLicenseAvailable",
    // The server does not support the feature.
    NotSupported: "notSupported",
    // The server is unavailable (HTTP error 502, 503 or 504).
    Unavailable: "unavailable",
    // A Login attempt of an user who hasn't validated his email has occured.
    UnvalidatedEmail: "unvalidatedEmail",
    // The server does not support the client's version anymore. The client must be upgraded.
    UpgradeRequired: "upgradeRequired",
    // The user agent is not connected to any server.
    LoggedOut: "loggedOut",
    // The user is the last owner of the space and cannot be demoted.
    CannotDemote: "cannotDemote",
    // The files are partially synchronized.
    IncompleteSync: "incompleteSync",
    // The password is too short.
    PasswordTooShort: "passwordTooShort",
    // The password is too weak.
    PasswordTooWeak: "passwordTooWeak",
    // The password is too long.
    PasswordTooLong: "passwordTooLong",
    // The sharing key is invalid.
    BadKey: "badKey",
    // The sharing key is too long or too short.
    BadKeySize: "badKeySize",
    // The user agent fails to connect the web socket to the instant messaging server.
    MessagingConnectionRefused: "messagingConnectionRefused",
    // The user agent is not connected to the instant messaging server.
    MessagingConnectionClosed: "messagingConnectionClosed",
    // The recovery has already been validated by another admin.
    RecoveryAlreadyValidated: "recoveryAlreadyValidated",
    // The given rdv_user_id does not match any existing channel.
    RecoveryBadChannel: "recoveryBadChannel",
    // No ongoing recovery with the specified recovery ID.
    RecoveryBadID: "recoveryBadId",
    // The trustee enterred a bad SAS for the current session.
    RecoveryBadSAS: "recoveryBadSas",
    // The trustee does not exist or is not part of the user trustees.
    RecoveryBadTrustee: "recoveryBadTrustee",
    // The recovery rdv channel is broken on trusty side.
    RecoverySessionBroken: "recoverySessionBroken",
    // The recovery rdv channel has expired.
    RecoverySessionExpired: "recoverySessionExpired",
    // The recovery session is not connected.
    RecoverySessionDisconnected: "recoverySessionDisconnected",
    // The DLP server has rejected the file upload.
    RejectedByDLP: "rejectedByDlp",
    // The antivirus has rejected the file download.
    RejectedByAV: "rejectedByAv",
    // The space has not thumbnail image.
    NoThumbnail: "noThumbnail",
    // The format is not supported.
    UnknownImageFormat: "unknownImageFormat",
    // Wrong or expired one-time password.
    InvalidOTP: "invalidOtp",
    // Too many OTP attempts. User should retry later.
    TooManyOTPAttempts: "tooManyOtpAttempts",
    // Wrong deposit box code.
    BadDepositBoxCode: "badDepositBoxCode",
    // The recipient entered too many invalid codes.
    MaxCodeAttemptsReached: "maxCodeAttemptsReached",
  },

  // The low-level event kinds.
  // See EventRecord.
  EventKind: {
    // Encrypted user storage update.
    EUS: "eus",
    // Space event update.
    SpaceEvent: "spaceEvent",
    // File revision update.
    FileRevision: "fileRevision",
    // File subscriptions update.
    FileSubscriptions: "fileSubscriptions",
    // Notifications update. SpaceID may be set depending on the notifications.
    Notifications: "notifications",
    // User status update.
    UserStatus: "userStatus",
    // Device status update.
    DeviceStatus: "deviceStatus",
    // Security levels update.
    SecurityLevels: "securityLevels",
    // Server settings update.
    Settings: "settings",
    // Legacy.
    Account: "account",
  },

  // File change.
  FileHistoryAction: {
    // Created.
    Add: "add",
    // Modified.
    Modify: "modify",
    // Moved.
    Move: "move",
    // Renamed.
    Rename: "rename",
    // Deleted.
    Delete: "delete",
  },

  // File download list type.
  DownloadListType: {
    // By sharing.
    FromSharing: "fromSharing",
    // By authenticated users.
    ByUsers: "byUsers",
  },

  // File history direction
  FileHistoryDirection: {
    Before: "before",
    After: "after",
  },

  // UniqueDownload defines the options to limit the number of download of a sharing link.
  UniqueDownload: {
    // The sharing link may be downloaded any number of times.
    Disabled: "disabled",
    // The sharing link may be downloaded once.
    Downloadable: "downloadable",
    // The sharing link may not be downloaded anymore. This value is set by the server.
    Downloaded: "downloaded",
  },

  // File upload status.
  FileUploadStatus: {
    // The upload is about to start. It is waiting for other transfers to complete.
    Pending: "pending",
    // The upload is in progress.
    Inflight: "inflight",
    // The upload has failed.
    Failed: "failed",
    // The upload has completed with success.
    Done: "done",
  },

  // DepositBoxState defines the different states of a deposit box.
  // - The default state is DepositBoxStateEmpty.
  // - The server changes the state to DepositBoxStatePopulatedUnread when the submitter uploads the files.
  // - The client application then marks the deposit box as read with DepositBoxStatePopulatedRead and processed with DepositBoxStatePopulatedProcessed.
  // 
  // The state is only informative.
  DepositBoxState: {
    // Default state.
    Empty: "empty",
    // The submitter has uploaded files.
    PopulatedUnread: "populatedUnread",
    // The owner has opened the deposit box after files were uploaded.
    PopulatedRead: "populatedRead",
    // The owner has marked the deposit box as processed (after files were uploaded).
    PopulatedProcessed: "populatedProcessed",
  },

  // The invite event types.
  // See InviteEvent.
  InviteEventType: {
    // Created.
    Created: "created",
    // Resent.
    Resent: "resent",
    // Accepted.
    Accepted: "accepted",
    // Expired.
    Expired: "expired",
    // Cancelled.
    Cancelled: "cancelled",
  },

  // The sorting fields for the invite list.
  // See InviteCollection's List().
  InviteOrderBy: {
    // Sort by last activity.
    LastActivity: "lastActivity",
    // Sort by creation date.
    CreationDate: "creationDate",
  },

  // The sorting orders for the invite list.
  // See InviteCollection's List().
  Sort: {
    // Sort in ascending order.
    Asc: "asc",
    // Sort in descending order.
    Desc: "desc",
  },

  // The invites pool status.
  // See InvitesPool and LicenseUsage.
  InvitePoolStatus: {
    // The number of invites is limited by LicenseUsage's Invitees.
    Disable: "disable",
    // The number of invites is limited by both LicenseUsage's Invitees and LicenseUsage's PersonalInvitesPool.
    Enable: "enable",
  },

  // The license pool of a user.
  // See UserType.
  LicensePool: {
    // Regular user.
    User: "user",
    // Invitee.
    Invitee: "invitee",
  },

  // The instant message statuses.
  // See MessageRecord.
  MessageStatus: {
    // The message is ready to be posted.
    Pending: "pending",
    // The message is being posted, its attachments are being uploaded.
    Inflight: "inflight",
    // The message has been delivered to the server.
    Delivered: "delivered",
    // The message has been rejected by the server. It may be posted again.
    Rejected: "rejected",
    // The message has been deleted. MessageRecord's Text contains a default message and MessageRecord's Attachments is empty. Other fields should be set.
    Deleted: "deleted",
  },

  // The user notification states.
  // See NotificationRecord.
  NotificationState: {
    // Read.
    Read: "read",
    // Not read. This is the default state.
    Unread: "unread",
  },

  // Deprecated.
  LoginStep: {
    // The user agent is downloading the account information.
    Connecting: "connecting",
    // The user agent is downloading the information of the spaces.
    SpaceLoading: "spaceLoading",
  },

  // Deprecated.
  LoginReason: {
    // The user agent has reloaded a previous session from disk and the user was already authenticated.
    Init: "init",
    // The client application has called UserAgent's Login().
    Login: "login",
    // The client application has called AccountCollection's Register().
    Register: "register",
  },

  // Request status.
  PreaddedStatus: {
    // The user has been invited but is not registered.
    Invited: "invited",
    // The user is registered but has not been added to the space.
    Registered: "registered",
    // The user is registered and has been added to the space by another owner.
    // Note that the record will not be removed automatically.
    Added: "added",
  },

  // The security level colors.
  // See SecurityLevelRecord.
  SecurityLevelColor: {
    // Green.
    Green: "green",
    // Orange.
    Orange: "orange",
    // Red.
    Red: "red",
  },

  // The security level shapes.
  // See SecurityLevelRecord.
  SecurityLevelShape: {
    // Opened lock.
    OpenedLock: "openedLock",
    // Closed lock.
    LockedLock: "lockedLock",
  },

  // Deprecated.
  SecurityLevelsListOptions: {
    // List all the security levels.
    All: "all",
    // List only the security levels available to the logged in device.
    Device: "device",
  },

  // The server capabilities.
  // See ServerSettingsRecord.
  ServerCapability: {
    // Device registration.
    Devices: "devices",
    // Instant messaging.
    Messaging: "messaging",
    // Instant messaging. Recipients with read status.
    MessagingRecipients: "messagingRecipients",
    // Instant messaging. Message deletion.
    MessagingDeletion: "messagingDeletion",
    // Instant messaging. Email notification.
    MessagingEmailNotification: "messagingEmailNotification",
    // Firebase Cloud Messaging.
    FCM: "fcm",
    // Encrypted payload in push notifications.
    FCMEncryptedPayload: "fcmEncryptedPayload",
    // File history.
    FileHistory: "fileHistory",
    // Invites management.
    InvitesManagement: "invitesManagement",
    // Invite extension request.
    InviteExtensionRequest: "inviteExtensionRequest",
    // Trustee deletion.
    TrusteeDeletion: "trusteeDeletion",
    // File revision raw size.
    FileRevisionRawSize: "fileRevisionRawSize",
    // CFSB v2.
    CFSBV2: "cfsbv2",
    // File sharing date modification.
    FileSharingDateModification: "fileSharingDateModification",
    // Multiple files sharing.
    FileSharingMultipleFiles: "fileSharingMultipleFiles",
    // File sharing unique download.
    FileSharingUniqueDownload: "fileSharingUniqueDownload",
    // File sharing download notification.
    FileSharingAuthorSubscription: "fileSharingAuthorSubscription",
    // Session termination request.
    Logout: "logout",
    // The server discloses the setting for the retention delay. See ServerSettingsRecord's RetentionDelayDays and SpaceCollection's GetDeletionDates().
    RetentionDelay: "retentionDelay",
    // File tracking ID.
    TrackingID: "trackingId",
    // Space thumbnail.
    Thumbnail: "thumbnail",
  },

  // MinimalNumberOfOwnersPerWorkspaceMode defines how the minimal number of owners per workspace should be enforced.
  MinimalNumberOfOwnersPerWorkspaceMode: {
    // The minimal number of owners per worspace is mandatory.
    Enforced: "enforced",
    // The minimal number of owners per worspace is informative.
    Informative: "informative",
  },

  // DepositBox code policy.
  DepositBoxCodePolicy: {
    // The code is optional and disabled by default.
    None: "none",
    // The code is optional and enabled by default.
    EnabledByDefault: "enabledByDefault",
    // The code is mandatory.
    Enforced: "enforced",
  },

  // The space kinds.
  // See SpaceRecord.
  SpaceKind: {
    // Workspace.
    Workspace: "workspace",
    // Personal space.
    PersonalSpace: "personalSpace",
    // One-to-one conversation.
    DirectConversation: "directConversation",
    // Group conversation.
    GroupConversation: "groupConversation",
    // Backup.
    Backup: "backup",
    // Unsupported.
    Unsupported: "unsupported",
  },

  // Space access right.
  SpaceAccessRight: {
    // The user can edit the metadata, add and remove members.
    Edit: "edit",
    // The user can download, copy and export files outside the workspace.
    Export: "export",
    // The user can create and edit sharing links. NB. The user can always delete a sharing link accessible through SpaceAccessRightAllSharings or SpaceAccessRightOnlyMySharings, event without SpaceAccessRightShare.
    Share: "share",
    // The user can subscribe to file change notifications.
    Subscribe: "subscribe",
    // The user can write and move files.
    Write: "write",
    // The space can have categories.
    Categories: "categories",
    // The space can have a file history and the user can access it.
    History: "history",
    // The space can have members other than the owner.
    Members: "members",
    // The space can have messages.
    Messages: "messages",
    // The space can have UI notifications.
    Notifications: "notifications",
    // The user can view all the sharing links. She can edit them if SpaceAccessRightShare is set. SpaceAccessRightAllSharings and SpaceAccessRightOnlyMySharings are mutually exclusive.
    AllSharings: "allSharings",
    // The user can only view her own sharing links. She can edit them if SpaceAccessRightShare is set.
    OnlyMySharings: "onlyMySharings",
    // The space can have subscriptions.
    Subscriptions: "subscriptions",
  },

  // The space usages.
  // See PublicMetadata.
  SpaceUsage: {
    // File sharing usage
    FileSharing: "fileSharing",
    // Messaging usage
    Messaging: "messaging",
    // Backup
    Backup: "backup",
  },

  // The space member roles.
  // The role defines the rights of a user in a space. They are cumulative:
  // - An owner has all the rights of a member.
  // - A member has all the rights of an auditor.
  // - Etc.
  // See SpaceMemberRecord.
  Role: {
    // The user can update the space metadata and user list.
    Owner: "owner",
    // The user can write and share files.
    Member: "member",
    // The user can download files.
    Auditor: "auditor",
    // The user can preview files.
    Viewer: "viewer",
  },

  // The space statuses.
  // All spaces are active by default.
  // Neither the user agent nor the server enforce the status, it is rather an indication for the UI.
  // See SpaceRecord.
  SpaceStatus: {
    // The space is usable.
    Active: "active",
    // The space is read-only.
    Archived: "archived",
    // The space has been deleted and is inaccessible.
    Deleted: "deleted",
  },

  // The file synchronization modes.
  // See SyncSettingsRecord.
  SyncMode: {
    // The synchronization is disabled.
    Off: "off",
    // All the workspaces are synchronized.
    All: "all",
    // Only the workspaces specified in SyncSettings's SpaceIDs are synchronized.
    Custom: "custom",
  },

  // The file synchronization statuses.
  // See SyncStateRecord.
  SyncStatus: {
    // Disabled.
    Off: "off",
    // In progress.
    InProgress: "inProgress",
    // Done.
    Done: "done",
    // Done with errors.
    Failed: "failed",
  },

  // Enforced trustee status.
  EnforcedTrusteeStatus: {
    // The enforced trustee is properly set.
    OK: "ok",
    // The enforced trustee must be added.
    AdditionRequired: "additionRequired",
    // The enforced trustee must be replaced.
    ReplacementRequired: "replacementRequired",
    // The enforced trustee must be removed.
    RemovalRequired: "removalRequired",
    // The enforced trustee is properly set but old enforced trustees must be removed.
    CleanUpRequired: "cleanUpRequired",
  },

  // Invitee trustee status.
  InviteeTrusteeStatus: {
    // The invitee trustee is properly set.
    OK: "ok",
    // The invitee trustee must be added.
    AdditionRequired: "additionRequired",
  },

  // The user account states.
  // See UserStatusRecord.
  UserState: {
    // The account is valid and usable.
    Active: "active",
    // The account has been blocked by an administrator. It may be reactivated at a later time (also by an administrator).
    Blocked: "blocked",
    // The account has been deleted.
    Deleted: "deleted",
    // The account has been registered but is not usable yet. The user must validate her email address first.
    EmailValidationPending: "emailValidationPending",
    // Deprecated.
    AdminValidationPending: "adminValidationPending",
  },

  // PasswordScoreColor is the background color for PasswordScore.
  PasswordScoreColor: {
    // Red. The password is too weak.
    Red: "red",
    // Orange. Depending on the server settings, the password is too weak or good enough; see PasswordScore's OK.
    Orange: "orange",
    // Green. The password is good.
    Green: "green",
  },

  // The strengths returned by ValidationModule's GetPasswordStrength().
  PasswordStrength: {
    // The password is too weak.
    Weak: "weak",
    // The password is good enough.
    Good: "good",
    // The password is strong.
    Strong: "strong",
  },

};


import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { action } from '@ember/object';
/* global libcryptobox */

export default class DownloadShare extends Route {
  @service intl;
  @service connection;

  @action
  didTransition() {
    this.controller.set('inputKey', '');
    this.controller.set('maxCodeAttemptsReached', false);
    return true;
  }

  model(params) {
    const model = params;
    model.keyHint = '';
    model.fileSize = '';
    return tBoxClient.fileSharing
      .getInfo(window.location.href)
      .then(async (encInfos) => {
        if (model.key) {
          return tBoxClient.fileSharing
            .decryptInfo(this.connection.serverAddress, encInfos, model.key)
            .then((fileInfos) => {
              model.name = fileInfos.fileName;
              model.fileSize = fileInfos.fileSize;
              return model;
            });
        } else {
          model.keyHint = await tBoxClient.fileSharing.getKeyHint(
            window.location.href,
          );
          model.encInfos = encInfos;
          return model;
        }
      })
      .catch((error) => {
        model.error = error;
        return model;
      });
  }

  afterModel() {
    document.title = 'Cryptobox - Your shared document';
  }
}

import pdfjsLib from 'pdfjs-dist';

export function initialize() {
  if (typeof window !== 'undefined') {
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      '/static/vendor/pdfjs-dist/build/pdf.worker.mjs';
  }
}

export default {
  name: 'pdfjs',
  initialize,
};

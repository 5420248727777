import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PdfViewerContentComponent extends Component {

  @service('pdfjs-service') pdfjsService;

  constructor() {
    super(...arguments);
  }

  get pdfViewer() {
    return this.pdfjsService.pdfViewer;
  }

  get findController() {
    return this.pdfjsService.findController;
  }

  get eventBus() {
    return this.pdfjsService.eventBus;
  }

  get alignToolbarItemPosition() {
    return this.pdfjsService.alignToolbarItemPosition;
  }

  @action
  async initializePdfViewer() {
    document.getElementById('zoomIn').addEventListener('click', () => {
      this.pdfViewer.currentScale *= 1.1;
      const scale = Math.round(this.pdfViewer.currentScale * 100) / 100;
      const scaleSelect = document.getElementById('scaleSelect');
      const customOption = document.getElementById('customScaleOption');

      customOption.style.display = 'block';
      customOption.removeAttribute('hidden');
      customOption.removeAttribute('disabled');
      customOption.textContent = `${Math.round(scale * 100)}%`;
      customOption.value = 'custom';
      scaleSelect.value = 'custom';
    });

    document.getElementById('zoomOut').addEventListener('click', () => {
      this.pdfViewer.currentScale /= 1.1;
      const scale = Math.round(this.pdfViewer.currentScale * 100) / 100;
      const scaleSelect = document.getElementById('scaleSelect');
      const customOption = document.getElementById('customScaleOption');
      customOption.style.display = 'block';
      customOption.removeAttribute('hidden');
      customOption.removeAttribute('disabled');
      customOption.textContent = `${Math.round(scale * 100)}%`;
      customOption.value = 'custom';
      scaleSelect.value = 'custom';
    });

    document.getElementById('previous').addEventListener('click', () => {
      if (this.pdfViewer.currentPageNumber > 1) {
        this.pdfViewer.previousPage();
      }
    });

    document.getElementById('next').addEventListener('click', () => {
      if (this.pdfViewer.currentPageNumber < this.pdfViewer.pagesCount) {
        this.pdfViewer.nextPage();
      }
    });
    document.getElementById('viewFindButton').addEventListener('click', () => {
      this.alignToolbarItemPosition();

      const findBar = document.getElementById('findbar');
      findBar?.classList?.toggle('hidden');
    });

    document
      .getElementById('secondaryToolbarToggle')
      .addEventListener('click', () => {
        this.alignToolbarItemPosition();

        const secondaryToolbar = document.getElementById('secondaryToolbar');
        secondaryToolbar?.classList?.toggle('hidden');
      });

    document.getElementById('scaleSelect').addEventListener('change', (e) => {
      const scaleValue = e.target.value;
      if (scaleValue === 'auto') {
        this.pdfViewer.currentScaleValue = 'auto';
      } else if (scaleValue === 'page-fit') {
        this.pdfViewer.currentScaleValue = 'page-fit';
      } else if (scaleValue === 'page-width') {
        this.pdfViewer.currentScaleValue = 'page-width';
      } else if (scaleValue === 'page-actual') {
        this.pdfViewer.currentScaleValue = 1.0;
      } else {
        const numericScale = parseFloat(scaleValue);
        if (!isNaN(numericScale)) {
          this.pdfViewer.currentScaleValue = numericScale;
        } else {
          console.error('Invalid scale value:', scaleValue);
        }
      }
    });

    document.getElementById('pageNumber').addEventListener('change', (e) => {
      this.pdfViewer.currentPageNumber = Number(e.target.value);
    });

    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault();
      }
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const findBar = document.getElementById('findbar');
        if (!findBar?.classList?.contains('hidden')) {
          findBar?.classList.add('hidden');
        }
      }
    });
    document.getElementById('findInput').addEventListener('input', (e) => {
      this.eventBus.dispatch('find', {
        query: e.target.value,
        phraseSearch: true,
        caseSensitive: document.getElementById('findMatchCase').checked,
        entireWord: document.getElementById('findEntireWord').checked,
      });
    });

    document.getElementById('findPrevious').addEventListener('click', () => {
      this.eventBus.dispatch('find', {
        query: document.getElementById('findInput').value,
        phraseSearch: true,
        caseSensitive: document.getElementById('findMatchCase').checked,
        entireWord: document.getElementById('findEntireWord').checked,
        highlightAll: document.getElementById('findHighlightAll').checked,
        findPrevious: true,
      });
    });

    document.getElementById('findNext').addEventListener('click', () => {
      this.eventBus.dispatch('find', {
        query: document.getElementById('findInput').value,
        phraseSearch: true,
        caseSensitive: document.getElementById('findMatchCase').checked,
        entireWord: document.getElementById('findEntireWord').checked,
        highlightAll: document.getElementById('findHighlightAll').checked,
        diacritics: document.getElementById('findMatchDiacritics').checked,
        findPrevious: false,
      });
    });
    this.eventBus.on('pagesloaded', (evt) => {
      const pageNumber = evt.pagesCount;
      if (pageNumber <= 1) {
        document.getElementById('previous').setAttribute('disabled', true);
        document.getElementById('next').setAttribute('disabled', true);
      }
      document.getElementById('pageNumber').setAttribute('max', pageNumber);
    });

    this.eventBus.on('pagechanging', (evt) => {
      const page = evt.pageNumber;
      document.getElementById('previous').disabled = page <= 1;
      document.getElementById('next').disabled =
        page >= this.pdfViewer.pagesCount;

      document.getElementById('pageNumber').value = page;
    });

    this.eventBus.on('scalechanging', (evt) => {
      document.getElementById('scaleSelect').value = evt.scale;
    });

    this.eventBus.on('updatefindcontrolstate', (evt) => {
      document.getElementById('findPrevious').disabled = !evt.canFind;
      document.getElementById('findNext').disabled = !evt.canFind;

      const findInput = document.getElementById('findInput');
      findInput?.classList?.toggle('not-found', evt.notFound);

      const findMsg = document.getElementById('findMsg');
      findMsg.textContent = evt.notFound ? 'No matches found' : '';
    });

    this.eventBus.on('updatefindmatchescount', (evt) => {
      const findInput = document.getElementById('findInput');
      findInput?.classList?.toggle('not-found', evt.matchesCount === 0);

      const findPrevious = document.getElementById('findPrevious');
      findPrevious.disabled = evt.matchesCount === 0;

      const findNext = document.getElementById('findNext');
      findNext.disabled = evt.matchesCount === 0;

      const findBar = document.getElementById('findbar');
      findBar?.classList?.toggle('not-found', evt.matchesCount === 0);

      const findMsg = document.getElementById('findMsg');
      findMsg.textContent = evt.matchesCount === 0 ? 'No matches found' : '';

      const findHighlightAll = document.getElementById('findHighlightAll');
      findHighlightAll.disabled = evt.matchesCount === 0;

      const findMatchCase = document.getElementById('findMatchCase');
      findMatchCase.disabled = evt.matchesCount === 0;

      const findEntireWord = document.getElementById('findEntireWord');
      findEntireWord.disabled = evt.matchesCount === 0;

      const findBarResultsCount = document.getElementById('findResultsCount');
      findBarResultsCount.textContent = `Occurrence ${evt.matchesCount.current} sur ${evt.matchesCount.total}`;
    });

    document
      .getElementById('presentationMode')
      .addEventListener('click', () => {
        this.pdfjsService.togglePresentationMode();
      });

    document.getElementById('firstPage').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.currentPageNumber = 1;
    });

    document.getElementById('lastPage').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.currentPageNumber = this.pdfViewer.pagesCount;
    });

    document
      .getElementById('cursorSelectTool')
      .addEventListener('click', () => {
        this.pdfjsService.selectTool();
      });

    document.getElementById('cursorHandTool').addEventListener('click', () => {
      this.pdfjsService.handTool();
    });

    document.getElementById('pageRotateCw').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.pagesRotation =
        (this.pdfjsService.pdfViewer.pagesRotation + 90) % 360;
    });
    document.getElementById('pageRotateCcw').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.pagesRotation =
        (this.pdfjsService.pdfViewer.pagesRotation - 90) % 360;
    });
    ['None', 'Odd', 'Even'].forEach((mode) => {
      document
        .getElementById(`spread${mode}`)
        ?.addEventListener('click', () => {
          this.pdfjsService.setSpreadMode(mode.toLowerCase());
        });
    });
    document.getElementById('scrollWrapped').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.scrollMode = 0;
    });
    ['Page', 'Vertical', 'Horizontal', 'Wrapped'].forEach((mode) => {
      document
        .getElementById(`scroll${mode}`)
        ?.addEventListener('click', () => {
          this.pdfjsService.setScrollMode(mode.toLowerCase());
        });
    });
    document
      .getElementById('documentProperties')
      ?.addEventListener('click', () => {
        this.pdfjsService.showDocumentProperties();
      });

    document
      .getElementById('documentPropertiesClose')
      ?.addEventListener('click', () => {
        document.getElementById('documentPropertiesDialog').close();
      });

    document.getElementById('passwordCancel').addEventListener('click', () => {
      document.getElementById('password').value = '';
      document.getElementById('passwordDialog').close();
      this.eventBus.dispatch('passwordcancel');
    });

    document.getElementById('passwordSubmit').addEventListener('click', () => {
      const password = document.getElementById('password').value;
      document.getElementById('passwordDialog').close();
      document.getElementById('password').value = '';

      this.pdfjsService.loadPdf(this.pdfjsService.pdfUrl, password);
    });

    this.pdfjsService.eventBus.on('cursortoolchanged', (evt) => {
      const { tool } = evt;
      this.pdfjsService.setCursorTool(tool);
    });
  }
}

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import Swiper, { Navigation, Pagination } from 'swiper';
import BlobFileWriter from 'client/toolbox/blob-file-writer';
import Viewer from 'viewerjs';
import { FILE_TYPE_IMAGE, FILE_TYPE_PDF, FILE_TYPE_UNKNOWN } from './fileInfoPreview';

export default class FileViewerComponent extends Component {
  @service modalManager;

  @tracked previewFileList = [];

  @tracked isLoading = false;

  @tracked activeIndex;

  @tracked currentPreviewFile;

  @service notify;

  @service('transfers') transfersManager;
  @service('pdfjs-service') pdfjsService;

  previewTypeImage = FILE_TYPE_IMAGE;
  previewTypePdf = FILE_TYPE_PDF;
  previewTypeUnknown = FILE_TYPE_UNKNOWN;
  changeObserver = new MutationObserver((mutationList, _) => {
    for (const mutation of mutationList) {
      if (
        mutation.target.id === 'viewFindButton' ||
        mutation.target.id === 'secondaryToolbarToggle'
      ) {
        this.pdfjsService.alignToolbarItemPosition();
      }
    }
  });

  constructor() {
    super(...arguments);

    this.previewFileList = [...this.args.fileInfoPreviews];

    this.initSwiper = this.initSwiper.bind(this);
    this.onImageLoadError = this.onImageLoadError.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
  }

  get hasSingleItem() {
    return this.previewFileList?.length === 1;
  }

  swiper = new Swiper('.swiper-container', {
    modules: [Navigation, Pagination],
    direction: 'horizontal',
    initialSlide: this.args.initialIndex ? this.args.initialIndex : 0,
    loop: true,
    init: false,
    lazy: true,
    simulateTouch: false,

    navigation: {
      nextEl: '.document-viewer-button-next',
      prevEl: '.document-viewer-button-previous',
    },
  });

  initSwiper() {
    document.addEventListener('keydown', this.keyListener);
    window.addEventListener('click', this.clickOutsideListener);

    this.changeObserver.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    this.swiper.on('slideChangeTransitionEnd', async () => {
      this.activeIndex = this.swiper.realIndex;
    });
    this.swiper.on('realIndexChange', async () => {
      this.onIndexChange(this.swiper.realIndex);
    });

    this.swiper.init();
    if (!this.args.initialIndex) {
      this.onIndexChange(0);
    } else {
      this.currentPreviewFile = this.previewFileList[this.args.initialIndex];
    }
  }

  get viewerRoleMode() {
    return this.args.viewerRoleMode;
  }

  keyListener = (event) => {
    if (event.key === 'ArrowLeft') {
      this.swiper.slidePrev();
    }
    if (event.key === 'ArrowRight') {
      this.swiper.slideNext();
    }
    if (event.key === 'Escape') {
      this.onCloseClicked();
    }
  };

  clickOutsideListener = (event) => {
    const outsideElements = [
      'viewer-canvas',
      'slide-container',
      'viewer',
      'mainContainer',
    ];
    if (
      outsideElements.includes(event.target.id) ||
      event.target.className === 'viewer-canvas'
    ) {
      this.onCloseClicked();
    }
  };

  @action
  onFileItemSelected(index) {
    const previewFile = this.previewFileList[index];
    if (previewFile.url && previewFile.type === this.previewTypePdf) {
      this.pdfjsService.fileName = previewFile.name;
      return this.pdfjsService.loadPdf(previewFile.url);
    }

    if (
      !this.viewerRoleMode ||
      (this.viewerRoleMode && previewFile.type === this.previewTypePdf)
    ) {
      this.isLoading = true;

      const downloadFilePromise = previewFile.attachmentId
        ? this.downloadAttachment(
            previewFile.attachmentId,
            previewFile.mimeType,
          )
        : this.downloadFile(
            this.args.spaceId,
            previewFile.spacePath,
            previewFile.mimeType,
            this.args.spaceFileRevision,
          );
      downloadFilePromise
        .then(async (blobUrl) => {
          this.isLoading = false;

          previewFile.url = blobUrl;
          if (previewFile.type === this.previewTypePdf) {
            this.pdfjsService.fileName = previewFile.name;
            await this.pdfjsService.loadPdf(blobUrl);
          }
        })
        .catch((error) => {
          previewFile.errorMessage = error.message;
          this.isLoading = false;
          console.error(error, 'Error loading file');
        });
    }
  }

  @action
  async onDownloadClicked() {
    const currentPreviewFile = this.currentPreviewFile;
    const currentFileBlobUrl = currentPreviewFile.url;
    const currentFileName = currentPreviewFile.name;
    if (currentFileBlobUrl) {
      // eslint-disable-next-line no-undef
      saveAs(currentFileBlobUrl, currentFileName);
    } else {
      this.transfersManager.downloadFile(currentPreviewFile.fileId, 0);
    }
  }

  @action
  onCloseClicked() {
    document.removeEventListener('keydown', this.keyListener);
    window.removeEventListener('click', this.clickOutsideListener);
    this.changeObserver.disconnect();
    this.previewFileList.forEach((fileInfoPreview) => {
      if (fileInfoPreview.url) {
        URL.revokeObjectURL(fileInfoPreview.url);
        fileInfoPreview.url = '';
      }
    });
    this.args.onClose();
  }



  onImageLoaded(element) {
    const viewer = new Viewer(element, {
      inline: true,
      backdrop: false,
      title: false,
      button: false,
      navbar: false,
      transition: false,
      toolbar: {
        zoomIn: 1,
        zoomOut: 1,
        reset: 1,
        prev: 0,
        play: {
          show: 1,
          size: 'large',
        },
        next: 0,
        rotateLeft: 1,
        rotateRight: 1,
        flipHorizontal: 1,
        flipVertical: 1,
      },
    });

    element.addEventListener('load', function () {
      viewer.update();
      element.removeEventListener('load', this);
    });
  }

  onImageLoadError(index) {
    if (index === this.activeIndex) {
      const previewFile = this.previewFileList[index];
      if (previewFile.url) {
        previewFile.errorMessage = 'image load error';
      }
    }
  }

  onIndexChange(index) {
    this.currentPreviewFile = this.previewFileList[index];

    if (this.currentPreviewFile.type === this.previewTypeImage) {
      this.onFileItemSelected(this.swiper.realIndex);
    }

    this.activeIndex = this.swiper.realIndex;
  }

  downloadFile(spaceId, spacePath, mimeType, fileRevision) {
    const writer = new BlobFileWriter();
    return tBoxClient.file
      .download(spaceId, spacePath, fileRevision, writer)
      .then(() => {
        const blob = new Blob(writer.chunkBlobs, { type: mimeType });
        return URL.createObjectURL(blob);
      });
  }

  downloadAttachment(attachmentId, mimeType) {
    const writer = new BlobFileWriter();
    return tBoxClient.attachment.download(attachmentId, writer).then(() => {
      const blob = new Blob(writer.chunkBlobs, { type: mimeType });
      return URL.createObjectURL(blob);
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.changeObserver.disconnect();
  }
}

import { Promise as EmberPromise } from 'rsvp';
import Service, { inject as service } from '@ember/service';
import fileDownload from 'client/custom-objects/file-download';
import fileUpload from 'client/custom-objects/file-upload';
import { getFileDownloadTarget } from 'client/toolbox/file-download-target';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { observes } from '@ember-decorators/object';
import { action } from '@ember/object';

function join(...paths) {
  const filteredPaths = paths.filter((path) => path);
  let joinedPath = filteredPaths.join('/');
  joinedPath = joinedPath.replace(/\/+/g, '/');
  if (joinedPath !== '/') {
    joinedPath = joinedPath.replace(/\/$/, '');
  }

  return joinedPath;
}

function dirname(path) {
  if (!path) {
    return '';
  }

  const components = path.split('/');
  components.pop();

  if (components.length === 0) {
    return '/';
  }

  return join(...components);
}

export default class Transfers extends Service {
  @service account;
  @service store;
  @service modalManager;
  @service notify;
  @service intl;
  @service connection;
  @service commonService;

  @tracked fileAccessLogs = null;
  @tracked shareDownload = null;
  // parentFolderId: null, //used to refresh folder after transfers are done

  @tracked queued = [];
  @tracked active = [];
  @tracked completed = [];
  @tracked failed = [];
  @tracked currentFiles = [];
  @tracked conflictObject;
  @tracked globalRemainingTime = 0;

  depositBoxUploadId = 1;

  refreshLogs(spaceId, path) {
    this.fileAccessLogs = this.store.query('fileAccessLog', {
      spaceId: spaceId,
      path: path,
    });
  }

  get isTransfering() {
    return this.active.length || this.queued.length;
  }

  updateTransferTimeId;

  updateTransferRemainingTime(time) {
    this.globalRemainingTime = time;
    this.updateTransferTimeId = null;
  }

  @observes(
    'queued.@each.remainSize',
    'queued.@each.size',
    'active.@each.size',
    'active.@each.remainSize',
    'active.@each.speed',
    'active.@each.state',
  )
  updateGlobalRemaningTime() {
    const allQueuedRemainingSize = this.queued.map( item => item.remainSize);
    const allActiveRemainingSize = this.active.map(item => item.remainSize);
    const allActiveSpeed = this.active.map(item => item.speed);
    const globalRemainingSize =
      allQueuedRemainingSize.reduce(
        (previous, current) => previous + current,
        0,
      ) +
      allActiveRemainingSize.reduce(
        (previous, current) => previous + current,
        0,
      );
    const globalSpeed = allActiveSpeed.reduce(
      (previous, current) => previous + current,
      0,
    );
    if (this && this.isTransfering) {
      const time = Math.round(globalRemainingSize / globalSpeed);
      if (isFinite(time)) {
        this.globalRemainingTime = time;
      }
    }
  }

  @observes('active.@each.state')
  updateTransfersList() {
    const activeTransfers = this.active;
    const completedTransfers = this.completed;
    const failedTransfers = this.failed;

    const completedTransfer = activeTransfers.findBy('state', 'completed');
    if (completedTransfer) {
      // Move to completed list
      activeTransfers.removeObject(completedTransfer);
      if (completedTransfer.kind !== 'history') {
        completedTransfers.unshiftObject(completedTransfer);
        this.currentFiles.pushObject(completedTransfer);
      }

      this.startNextTransfer();

      if (activeTransfers.length === 0) {
        this.currentFiles.length = 0;
      }
    }

    const failedtransfer = activeTransfers.findBy('state', 'failed');
    if (failedtransfer) {
      // Move to failed list
      failedTransfers.unshiftObject(failedtransfer);
      activeTransfers.removeObject(failedtransfer);

      this.startNextTransfer();
    }
  }

  /**
   * make a notification at each change in the queue of transfers
   */
  @observes('active.length')
  transfersToast() {
    const activeTransfers = this.active;
    const completedTransfersToToast = this.completed.filter(item => item.toasted === false);
    const failedTransfersToToast = this.failed.filter(item => item.toasted === false);
    const notify = this.notify;
    /**
     * at the point where all transferts are done, notify for each case.
     */
    if (activeTransfers.get('length') === 0) {
      if (completedTransfersToToast.get('length') > 0) {
        if (
          completedTransfersToToast.isAny('direction', 'upload') &&
          completedTransfersToToast.isAny('direction', 'download')
        ) {
          notify.success(this.intl.t('toasts.endTransfers.message'), {
            title: this.intl.t('toasts.endTransfers.title'),
            linkProperties: {
              route: 'my-transfers',
            },
            icon: 'transfers',
          });
        } else if (!completedTransfersToToast.isAny('direction', 'download')) {
          if (completedTransfersToToast.isAny('kind', 'deposit_box_form')) {
            return;
          }
          notify.success(
            this.intl.t('toasts.endUpload.message', {
              count: completedTransfersToToast.get('length'),
            }),
            {
              title: this.intl.t('toasts.endUpload.title', {
                count: completedTransfersToToast.get('length'),
              }),
              linkProperties: {
                route: 'my-transfers',
              },
              icon: 'transfers',
            },
          );
        } else if (!completedTransfersToToast.isAny('direction', 'upload')) {
          notify.success(
            this.intl.t('toasts.endDownload.message', {
              count: completedTransfersToToast.get('length'),
            }),
            {
              title: this.intl.t('toasts.endDownload.title', {
                count: completedTransfersToToast.get('length'),
              }),
              linkProperties: {
                route: 'my-transfers',
              },
              icon: 'transfers',
            },
          );
        } else {
          notify.success(
            this.intl.t('toasts.endDownload.message', {
              count: completedTransfersToToast.get('length'),
            }),
            {
              title: this.intl.t('toasts.endDownload.title', {
                count: completedTransfersToToast.get('length'),
              }),
              linkProperties: {
                route: 'my-transfers',
              },
              icon: 'transfers',
            },
          );
        }
        /**
         * some transfers have failed
         */
        if (failedTransfersToToast.get('length') > 0) {
          if (
            failedTransfersToToast.isAny('direction', 'upload') &&
            failedTransfersToToast.isAny('direction', 'download')
          ) {
            notify.error(this.intl.t('toasts.someTransfersFailed.message'), {
              title: this.intl.t('toasts.someTransfersFailed.title'),
              linkProperties: {
                route: 'my-transfers',
              },
              icon: 'transfers',
            });
          } else if (!failedTransfersToToast.isAny('direction', 'download')) {
            notify.error(
              this.intl.t('toasts.someUploadFailed.message', {
                count: failedTransfersToToast.get('length'),
              }),
              {
                title: this.intl.t('toasts.someUploadFailed.title', {
                  count: failedTransfersToToast.get('length'),
                }),
                linkProperties: {
                  route: 'my-transfers',
                },
                icon: 'transfers',
              },
            );
          } else {
            notify.error(
              this.intl.t('toasts.someDownloadFailed.message', {
                count: failedTransfersToToast.get('length'),
              }),
              {
                title: this.intl.t('toasts.someDownloadFailed.title', {
                  count: failedTransfersToToast.get('length'),
                }),
                linkProperties: {
                  route: 'my-transfers',
                },
                icon: 'transfers',
              },
            );
          }
          failedTransfersToToast.setEach('toasted', true);
        }
        completedTransfersToToast.setEach('toasted', true);
      } else if (failedTransfersToToast.get('length') > 0) {
        if (
          failedTransfersToToast.isAny('direction', 'upload') &&
          failedTransfersToToast.isAny('direction', 'download')
        ) {
          notify.error(this.intl.t('toasts.transfersFailed.message'), {
            title: this.intl.t('toasts.transfersFailed.title'),
            linkProperties: {
              route: 'my-transfers',
            },
            icon: 'transfers',
          });
        } else if (!failedTransfersToToast.isAny('direction', 'download')) {
          notify.error(
            this.intl.t('toasts.uploadFailed.message', {
              count: failedTransfersToToast.get('length'),
            }),
            {
              title: this.intl.t('toasts.uploadFailed.title', {
                count: failedTransfersToToast.get('length'),
              }),
              linkProperties: {
                route: 'my-transfers',
              },
              icon: 'transfers',
            },
          );
        } else {
          notify.error(
            this.intl.t('toasts.downloadFailed.message', {
              count: failedTransfersToToast.get('length'),
            }),
            {
              title: this.intl.t('toasts.downloadFailed.title', {
                count: failedTransfersToToast.get('length'),
              }),
              linkProperties: {
                route: 'my-transfers',
              },
              icon: 'transfers',
            },
          );
        }
        failedTransfersToToast.setEach('toasted', true);
      }
    } else {
    }
  }

  startNextTransfer() {
    const activeTransfers = this.active;
    const queuedTransfers = this.queued;
    const nextTransfer = queuedTransfers.shiftObject();
    if (nextTransfer) {
      nextTransfer.set('state', 'active');
      activeTransfers.pushObject(nextTransfer);
    }
  }

  async downloadFileByRevisionAndPath({ spaceId, revision, trackingId }) {
    const store = this.store;
    try {
      const file = await store.queryRecord('file', {
        find: { spaceId, trackingId, revision },
      });

      const target = getFileDownloadTarget(file.name, file.contentSize);
      if (!target) {
        return;
      }
      const downloadProperties = {
        workspaceRevision: revision,
        fileName: file.name,
        size: file.contentSize,
        target,
      };
      file.space
        .then((spaceRecord) => {
          downloadProperties.spaceId = spaceRecord.id;
          downloadProperties.spaceName = spaceRecord.name;
          return file.path;
        })
        .then((path) => {
          downloadProperties.path = path;
          fileDownload.revision = revision;

          if (this.active.length < 4) {
            downloadProperties.state = 'active';
            this.active.pushObject(fileDownload.create(downloadProperties));
          } else {
            downloadProperties.state = 'queued';
            this.queued.unshiftObject(fileDownload.create(downloadProperties));
          }

          this.notify.info(this.intl.t('toasts.startDownload.message'), {
            title: this.intl.t('toasts.startDownload.title'),
            linkProperties: {
              route: 'my-transfers',
            },
            icon: 'transfers',
          });
        })
        .then(() => {
          setTimeout(() => {
            this.refreshLogs(downloadProperties.spaceId, file.path);
          }, 500);
        });
    } catch (e) {
      console.error('Error downloadFileByRevisionAndPath', e);
    }
  }

  /**
   * Download Method
   * @param fileId
   * @param workspaceRevision
   */
  @action
  async downloadFile(fileId, workspaceRevision = 0) {
    const store = this.store;
    const fileRecord = store.peekRecord({ type: 'file', id: fileId });
    const target = await getFileDownloadTarget(
      fileRecord.get('name'),
      fileRecord.get('contentSize'),
    );
    if (!target) {
      return;
    }
    const downloadProperties = {
      workspaceRevision: workspaceRevision,
      fileName: fileRecord.get('name'),
      size: fileRecord.get('contentSize'),
      target,
      // errorManagementFunction: observerFactory.getErrorManagement(),
    };
    fileRecord
      .get('space')
      .then((spaceRecord) => {
        downloadProperties.spaceId = spaceRecord.get('id');
        downloadProperties.spaceName = spaceRecord.get('name');
        return fileRecord.get('path');
      })
      .then((path) => {
        downloadProperties.path = path;
        fileDownload.revision = workspaceRevision;

        if (this.active.length < 4) {
          downloadProperties.state = 'active';
          this.active.pushObject(fileDownload.create(downloadProperties));
        } else {
          downloadProperties.state = 'queued';
          this.queued.unshiftObject(fileDownload.create(downloadProperties));
        }
        const notify = this.notify;

        notify.info(this.intl.t('toasts.startDownload.message'), {
          title: this.intl.t('toasts.startDownload.title'),
          linkProperties: {
            route: 'my-transfers',
          },
          icon: 'transfers',
        });
      })
      .then(() => {
        setTimeout(() => {
          this.refreshLogs(downloadProperties.spaceId, fileRecord.get('path'));
        }, 500);
      });
  }

  async downloadFileHistory(spaceId, trackingId, name) {
    const target = await getFileDownloadTarget(name, 0);
    if (!target) {
      return;
    }

    const downloadProperties = {
      fileName: name,
      size: 0,
      trackingId: trackingId,
      kind: 'history',
      target,
      // errorManagementFunction: observerFactory.getErrorManagement(),
    };

    downloadProperties.spaceId = spaceId;
    downloadProperties.spaceName = name;

    downloadProperties.state = 'active';
    this.active.pushObject(fileDownload.create(downloadProperties));

    const notify = this.notify;

    notify.info(this.intl.t('toasts.startDownload.message'), {
      title: this.intl.t('toasts.startDownload.title'),
      linkProperties: {
        route: 'my-transfers',
      },
      icon: 'transfers',
    });
  }

  uploadFilesList(
    fileList,
    parentFolderPath,
    targetSpace,
    confirmUpload = false,
  ) {
    if (fileList.length > 1000 && !confirmUpload) {
      this.modalManager.open('filesCountWarning');
      this.pendingFiles = fileList;
      this.pendingFolder = parentFolderPath;
      this.pendingSpace = targetSpace;
      this.isFolder = false;
      return;
    }

    const files = [].slice.call(fileList);
    const uploadProperties = {
      spaceId: targetSpace.get('id'),
      spaceName: targetSpace.get('name'),
    };
    const folderPath = parentFolderPath.get('path');
    return this.checkConflict(files, parentFolderPath).then(
      (checkedFileListArray) => {
        /**
         * upload files one by one
         */
        checkedFileListArray.forEach((file) => {
          uploadProperties.fileName = file.name;
          uploadProperties.size = file.size;
          uploadProperties.path = join(folderPath, file.name);
          uploadProperties.fileData = file;
          uploadProperties.parentFolderPath = folderPath;
          this.uploadFile(uploadProperties);
        });
        this.notifyStartUpload(fileList.length);
      },
    );
  }

  uploadFileToDepositBoxUnAuthenticated(depositBoxFormId, file) {
    this.depositBoxUploadId++;
    const id = this.depositBoxUploadId;
    const uploadProperties = {
      kind: 'deposit_box_form',
      depositBoxFormId: depositBoxFormId,
      fileData: file,
      fileName: file.name,
      id: id,
      size: file.size,
    };

    uploadProperties.state = 'active';
    this.active.pushObject(fileUpload.create(uploadProperties));
  }

  @action
  async replaceFileContent(file, parentFolderPath, oldFile) {
    try {
      if (!file) {
        new Error('no file to upload');
      }
      this.uploadFile({
        spaceId: oldFile.get('space.id'),
        spaceName: oldFile.get('space.name'),
        fileName: oldFile.name,
        size: file.size,
        fileData: file,
        path: join(parentFolderPath.get('path'), oldFile.name),
        parentFolderPath: parentFolderPath.get('path'),
      });
    } catch (e) {
      console.error(e, 'error while replacing file content');
    }
  }

  /**
   * notif to start
   * @param countFiles
   */
  notifyStartUpload(countFiles) {
    const notify = this.notify;
    notify.info(
      this.intl.t('toasts.startUpload.message', { count: countFiles }),
      {
        title: `${this.intl.t('toasts.startUpload.title')}(${countFiles})`,
        linkProperties: {
          route: 'my-transfers',
        },
        icon: 'transfers',
      },
    );
  }

  /**
   * lists conflicting files to upload and asks user in a modal what to do.
   * then proceeds to upload accordingly.
   *
   * @param fileListArray
   * @param targetFolder
   * @returns {Promise}
   */
  checkConflict(fileListArray, targetFolder) {
    const transfersManager = this;
    return new EmberPromise(function (resolve, reject) {
      /**
       * choices in the modal
       * @type {{overwrite(): void, ignoreConflicts(): void, abortUpload(): void, fileConflicts: Array, folderConflicts: Array, unConflictFileListArray: Array}}
       */
      const conflictObject = {
        overwrite() {
          transfersManager.set('conflictObject', null);
          resolve(fileListArray);
          transfersManager.get('modalManager').close('node-conflict-modal');
        },
        ignoreConflicts() {
          transfersManager.set('conflictObject', null);
          if (this.unConflictFileListArray.length) {
            resolve(this.unConflictFileListArray);
          }
          transfersManager.get('modalManager').close('node-conflict-modal');
        },
        abortUpload() {
          transfersManager.set('conflictObject', null);
          reject();
        },
        fileConflicts: [],
        folderConflicts: [],
        unConflictFileListArray: [],
      };

      const spaceId = targetFolder.get('space.id');
      const path = targetFolder.get('path');

      const exists = function (path) {
        return tBoxClient.file.exists(spaceId, 0, path);
      };

      Promise.all(
        fileListArray.map((file) => {
          const relativePathArray = file.CBRelativePath.split('/');
          if (relativePathArray.length === 1) {
            return exists(path + '/' + file.name).then((ok) => {
              if (ok) {
                conflictObject.fileConflicts.push(file.name);
              } else {
                conflictObject.unConflictFileListArray.push(file);
              }
            });
          } else {
            return exists(path + '/' + file.CBRelativePath).then((ok) => {
              if (ok) {
                conflictObject.folderConflicts.push(relativePathArray[0]);
              } else {
                conflictObject.unConflictFileListArray.push(file);
              }
            });
          }
        }),
      ).then(() => {
        conflictObject.fileConflicts = conflictObject.fileConflicts.uniq();
        conflictObject.folderConflicts = conflictObject.folderConflicts.uniq();

        if (
          conflictObject.fileConflicts.length ||
          conflictObject.folderConflicts.length
        ) {
          transfersManager.set('conflictObject', conflictObject);
          transfersManager.get('modalManager').open('node-conflict-modal');
        } else {
          resolve(fileListArray);
        }
      });
    });
  }

  async downloadDepositBox(box, files) {
    const isZip = files.length > 1;

    const fileName = isZip ? `${box.name}.zip` : `${files[0].name}`;
    const boxId = box.id;
    const totalFilesSize = files.reduce((acc, file) => acc + file.size, 0);
    const fileNames = files.map((file) => file.name);
    const target = await getFileDownloadTarget(fileName, totalFilesSize);
    if (!target) {
      return;
    }
    const downloadProperties = {
      kind: 'downloadDepositBox',
      fileName,
      boxId,
      fileNames,
      target,
      isZip,
    };

    if (this.active.length < 4) {
      downloadProperties.state = 'active';
      this.active.pushObject(fileDownload.create(downloadProperties));
    } else {
      downloadProperties.state = 'queued';
      this.queued.unshiftObject(fileDownload.create(downloadProperties));
    }
  }

  async downloadZip(spaceId, sources, revision) {
    const fileName = await tBoxClient.file.getSuggestedZipName(
      spaceId,
      sources,
      revision,
    );
    const fileSize = await tBoxClient.file.getExpectedZipSize(
      spaceId,
      sources,
      revision,
    );
    const target = await getFileDownloadTarget(fileName, fileSize);
    if (!target) {
      return;
    }

    const downloadProperties = {
      spaceId: spaceId,
      sources: sources,
      workspaceRevision: revision,
      kind: 'zip',
      fileName,
      target,
    };

    if (this.active.length < 4) {
      downloadProperties.state = 'active';
      this.active.pushObject(fileDownload.create(downloadProperties));
    } else {
      downloadProperties.state = 'queued';
      this.queued.unshiftObject(fileDownload.create(downloadProperties));
    }
  }

  uploadFolderFilesList(
    fileListArray,
    parentFolderRecord,
    targetSpace,
    confirmUpload = false,
  ) {

    if (fileListArray.length > 1000 && !confirmUpload) {
      this.modalManager.open('filesCountWarning');
      this.pendingFiles = fileListArray;
      this.pendingFolder = parentFolderRecord;
      this.pendingSpace = targetSpace;
      this.isFolder = true;
      return;
    }

    const transfersManager = this;
    const uploadProperties = {
      spaceId: targetSpace.get('id'),
      spaceName: targetSpace.get('name'),
    };
    this.checkConflict(fileListArray, parentFolderRecord).then(
      (checkedFileListArray) => {
        const fileListIterator = this.fileListIterator(checkedFileListArray);
        const readFileList = function (fileListIterator) {
          const iteration = fileListIterator.next();
          if (iteration.done === true) {
            return;
          }
          const file = iteration.value;
          const path = join(
            parentFolderRecord.get('path'),
            file.CBRelativePath,
          );
          uploadProperties.fileName = file.name;
          uploadProperties.size = file.size;
          uploadProperties.path = path;
          uploadProperties.fileData = file;
          uploadProperties.parentFolderPath = dirname(path);
          transfersManager.uploadFile(uploadProperties);
          readFileList(fileListIterator);
        };
        const notify = this.notify;
        notify.info(
          this.intl.t('toasts.startUpload.message', {
            count: fileListArray.length,
          }),
          {
            title: this.intl.t('toasts.startUpload.title'),
            linkProperties: {
              route: 'my-transfers',
            },
            icon: 'transfers',
          },
        );
        readFileList(fileListIterator);
      },
    );
  }

  *fileListIterator(fileList) {
    for (const file of fileList) {
      yield file;
    }
    return true;
  }

  uploadFile(uploadProperties) {
    if (this.active.length < 4) {
      uploadProperties.state = 'active';
      this.active.pushObject(fileUpload.create(uploadProperties));
    } else {
      uploadProperties.state = 'queued';
      this.queued.pushObject(fileUpload.create(uploadProperties));
    }
  }

  retryUpload(transfer) {
    const activeTransfers = this.active;
    const failedTransfers = this.failed;

    if (!failedTransfers.length) {
      return;
    }

    failedTransfers.removeObject(transfer);
    activeTransfers.unshiftObject(transfer);
    transfer.retryTransfer();
  }
}

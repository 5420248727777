import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import shareDownloadObject from 'client/custom-objects/share-download';
import { getFileDownloadTarget } from 'client/toolbox/file-download-target';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class DownloadShare extends Controller {
  queryParams = ['key'];

  @tracked inputKey = '';
  @tracked maxCodeAttemptsReached = false;

  @service intl;
  @service transfers;
  @service modalManager;
  @service connection;

  constructor() {
    super(...arguments);
    set(this, 'transfers.shareDownload', null);
  }

  @computed('transfers.shareDownload')
  get isIdle() {
    const shareDownload = this.transfers.shareDownload;
    return !shareDownload;
  }

  @computed('transfers.shareDownload.state')
  get isDownloading() {
    const shareDownload = this.transfers.shareDownload;
    if (shareDownload) {
      if (shareDownload.state === 'active') {
        return true;
      }
    }
    return false;
  }

  @computed('transfers.shareDownload.state')
  get isCompleted() {
    const shareDownload = this.transfers.shareDownload;

    if (shareDownload) {
      if (shareDownload.state === 'completed') {
        return true;
      }
    }
    return false;
  }

  @computed('transfers.shareDownload.progressPercent')
  get progressBarBuffer() {
    return this.transfers.shareDownload?.progressPercent * 1.1;
  }

  @computed(
    'maxCodeAttemptsReached',
    'model.error',
    'transfers.shareDownload.state',
  )
  get displayDownloadError() {
    const shareDownload = this.transfers.shareDownload;

    return (
      this.maxCodeAttemptsReached ||
      this.model.error ||
      (shareDownload && shareDownload.state === 'onError')
    );
  }

  @action
  async checkKey(event) {
    event.preventDefault();
    try {
      const inputValue = this.inputKey || '';
      const key = inputValue.replace(/\s/g, '');
      const encInfos = this.model.encInfos;

      this.inputKey = key;

      const fileInfos = await tBoxClient.fileSharing.decryptInfo(
        this.connection.serverAddress,
        encInfos,
        key,
      );
      this.set('model.name', fileInfos.fileName);
      this.set('model.fileSize', fileInfos.fileSize);
      this.set('model.key', key);
    } catch (error) {
      document.querySelector('.form-key').classList.add('error');
      document.querySelector('.form-key .error-message').innerText =
        error.message;
      if (error.code === libcryptobox.ErrorCode.MaxCodeAttemptsReached) {
        this.set('model.error', error);
        this.maxCodeAttemptsReached = true;
      }
    }
  }

  @action
  async download() {
    const target = await getFileDownloadTarget(
      this.model.name,
      this.model.fileSize,
    );
    if (!target) {
      return;
    }
    const dl = shareDownloadObject.create({
      sharingUrl: window.location.href,
      key: this.model.key,
      fileName: this.model.name,
      size: this.model.fileSize,
      transfers: this.transfers,
      target,
    });
    if (dl.get('isCanceled')) {
      return;
    }
    dl.set('state', 'active');
    set(this, 'transfers.shareDownload', dl);
  }

  @action
  willDestroy() {
    super.willDestroy(...arguments);
    this.maxCodeAttemptsReached = false;
  }
}

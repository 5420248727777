/* global libcryptobox */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { DateTime } from 'luxon';
import { addDaysToDate } from '../../../../utils/dateTime';

export default class ListedShareComponent extends Component {
  @tracked modalTitle = '';
  @tracked hasExpirationDate;
  @tracked newExpirationDate;
  @tracked newUniqueDownload;
  @tracked minDate;
  @tracked maxDate;
  @tracked defaultDate;
  @tracked expirationDisabled = false;

  sharingLinkMaxPeriod;
  sharingLinkDefaultPeriod;
  @tracked inputElementId = guidFor(this);
  @tracked isNotify;

  @service modalManager;
  @service intl;

  constructor() {
    super(...arguments);
    tBoxClient.serverSettings.get().then((settings) => {
      this.sharingLinkMaxPeriod = settings.sharingLinkMaxPeriod;
      this.sharingLinkDefaultPeriod = settings.sharingLinkDefaultPeriod;
    });
  }

  setExpirationDate(
    sharingLinkMaxPeriod,
    sharingLinkDefaultPeriod,
    setDefaultExpirationDate,
  ) {
    const today = new Date();
    this.minDate = today;
    this.maxDate = null;

    let expDate;
    let hasExpDate;

    if (!sharingLinkMaxPeriod && !sharingLinkDefaultPeriod) {
      this.expirationDisabled = false;
      hasExpDate = false;
      expDate = today;
    } else if (sharingLinkMaxPeriod && !sharingLinkDefaultPeriod) {
      this.expirationDisabled = true;
      hasExpDate = true;
      expDate = addDaysToDate(today, sharingLinkMaxPeriod);
      this.maxDate = addDaysToDate(today, sharingLinkMaxPeriod);
    } else if (!sharingLinkMaxPeriod && sharingLinkDefaultPeriod) {
      this.expirationDisabled = false;
      hasExpDate = true;
      expDate = addDaysToDate(today, sharingLinkDefaultPeriod);
    } else if (sharingLinkMaxPeriod && sharingLinkDefaultPeriod) {
      this.expirationDisabled = true;
      hasExpDate = true;
      this.maxDate = addDaysToDate(today, sharingLinkMaxPeriod);

      if (sharingLinkDefaultPeriod > sharingLinkMaxPeriod) {
        expDate = addDaysToDate(today, sharingLinkMaxPeriod);
      } else {
        expDate = addDaysToDate(today, sharingLinkDefaultPeriod);
      }
    }
    if (this.expired) {
      this.newExpirationDate = expDate;
      this.defaultDate = expDate;
      this.hasExpirationDate = hasExpDate;
    }
    if (setDefaultExpirationDate) {
      this.newExpirationDate = expDate;
      this.defaultDate = expDate;
    }
  }

  get uniqueDownload() {
    return (
      this.args.share.uniqueDownload ===
      libcryptobox.UniqueDownload.Downloadable
    );
  }

  get isDownloaded() {
    return (
      this.args.share.uniqueDownload === libcryptobox.UniqueDownload.Downloaded
    );
  }

  get expirationDate() {
    return new Date(this.args.share.notAfter);
  }

  get isExpirationDateUpdated() {
    if (this.hasExpirationDate) {
      return !DateTime.fromJSDate(this.expirationDate).equals(
        DateTime.fromJSDate(this.newExpirationDate),
      );
    }
    return this.args.share.notAfter !== '';
  }

  get isUniqueDownloadUpdated() {
    return this.newUniqueDownload !== this.uniqueDownload;
  }

  get canSuspend() {
    if (this.isBlocked) return false;
    return this.args.space.isAdmin || this.args.space.isMember;
  }

  get expired() {
    if (this.isDownloaded) return true;

    const today = DateTime.now();
    return this.expirationDate && today > this.expirationDate;
  }

  get formatedRecipients() {
    if (this.args.share && this.args.share.recipients.length) {
      return this.args.share.recipients.join(', ');
    }

    return '';
  }

  get status() {
    if (this.expired) return 'expired';
    if (this.isBlocked) return 'blocked';
    if (this.args.share.suspended) return 'suspended';
    return 'active';
  }

  get isBlocked() {
    return this.args.share.maxCodeAttemptsReached;
  }
  get canEditNotify() {
    return (
      this.args.share.author.isMySelf &&
      this.args.share.isAuthorSubscriptionSupported
    );
  }

  get showNotifIcon() {
    if (this.isBlocked) return false;
    return (
      this.args.share.authorSubscription === this.args.share.author.isMySelf
    );
  }
  @action
  filterDate(date) {
    this.newExpirationDate = date;
  }

  @action
  confirmRemove() {
    this.modalManager.open(`removeShare-${ this.args.share.id }`);
  }

  @action
  removeShare() {
    this.args.share.destroyRecord();
  }

  @action
  openChangeExpiration(restartAction) {
    this.setModalTitle(restartAction);
    if (restartAction) {
      this.initRestart();
    } else {
      this.initEdit();
    }

    this.modalManager.open(`changeShareOptions-${ this.args.share.id }`);
  }

  initRestart() {
    this.newUniqueDownload = false;
    this.setExpirationDate(
      this.sharingLinkMaxPeriod,
      this.sharingLinkDefaultPeriod,
    );
    this.isNotify = false;
  }

  initEdit() {
    this.newUniqueDownload = this.uniqueDownload;
    this.hasExpirationDate = this.args.share.notAfter !== '';
    this.isNotify = this.args.share.authorSubscription;
    this.setDatePicker();
  }

  changeExpirationDate() {
    if (this.isExpirationDateUpdated || this.isDownloaded) {
      if (
        !this.hasExpirationDate ||
        !DateTime.fromJSDate(this.newExpirationDate).isValid
      ) {
        this.newExpirationDate = '';
      } else {
        this.newExpirationDate = DateTime.fromJSDate(this.newExpirationDate)
          .endOf('day')
          .toJSDate();
      }

      this.args.onChangeExpiration(this.args.share, DateTime.fromJSDate(this.newExpirationDate).toISO());
    }
  }

  changeUniqueDownload() {
    if (this.isUniqueDownloadUpdated || this.isDownloaded) {
      this.args.onChangeUniqueDownload(this.args.share, this.newUniqueDownload);
    }
  }

  changeAuthorSubscription() {
    if (this.args.share.authorSubscription !== this.isNotify) {
      this.args.onChangeAuthorSubscription(this.args.share, this.isNotify);
    }
  }

  @action
  onChangeShareOptions() {
    this.changeExpirationDate();
    this.changeUniqueDownload();
    this.changeAuthorSubscription();
    this.args.applyChanges(this.args.share);
  }

  @action
  openPicker() {
    const picker = document.querySelector(
      `#listed-share-expirationDate-picker-${this.inputElementId}`,
    );
    picker.click();
  }

  @action
  onCheckboxChange(event) {
    const { name, checked } = event;
    this[name] = checked;
  }

  setModalTitle(restart) {
    const restartId = restart ? 'restartTitle' : 'title';
    this.modalTitle = this.intl.t(`shares.modal.options.${ restartId }`);
  }

  setDatePicker() {
    const validDate = DateTime.fromJSDate(this.expirationDate).isValid;
    this.newExpirationDate = validDate ? this.expirationDate : this.minDate;
    this.setExpirationDate(
      this.sharingLinkMaxPeriod,
      this.sharingLinkDefaultPeriod,
      !this.hasExpirationDate,
    );
  }
}

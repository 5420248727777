import { tBoxClient } from 'client/initializers/init-toolbox';
import ENV from 'client/config/environment';
import FileWriter from './file-writer';
import { showSaveDialog } from 'client/ipc';
/* global libcryptobox */

// On the desktop, the method returns null if the user cancels the save dialog.
export async function getFileDownloadTarget(name, size) {
  if (ENV.APP.isDesktopApp) {
    const path = await showSaveDialog(name);
    return path ? new FileDownloadTarget(name, size, path) : null;
  }
  return new FileDownloadTarget(name, size);
}

// FileDownloadTarget provides the target to download a file with the toolbox.
// - The target depends on the platform and its capabilities.
//   See the constructor() below.
// - The class may prompt the end-user for a save location.
//   If you need to download the file to memory without notifying
//   the user, call the toolbox API directly with a BlobFileWriter.
class FileDownloadTarget {
  constructor(name, size, path = undefined) {
    this.name = name;
    this.size = size;
    this.path = path;
  }

  download(spaceId, path, revision) {
    return this.call(
      (target) => tBoxClient.file.download(spaceId, path, revision, target),
      (target) =>
        tBoxClient.file.downloadToDisk(spaceId, path, revision, target),
    );
  }

  downloadZip(spaceId, sources, revision) {
    return this.call(
      (target) =>
        tBoxClient.file.downloadZip(spaceId, sources, revision, target),
      (target) =>
        tBoxClient.file.downloadZipToDisk(spaceId, sources, revision, target),
    );
  }

  downloadSharing(sharingUrl, sharingKey) {
    return this.call(
      (target) =>
        tBoxClient.fileSharing.download(sharingUrl, sharingKey, target),
      (target) =>
        tBoxClient.fileSharing.downloadToDisk(sharingUrl, sharingKey, target),
    );
  }

  downloadAttachment(id) {
    return this.call(
      (target) => tBoxClient.attachment.download(id, target),
      (target) => tBoxClient.attachment.downloadToDisk(id, target),
    );
  }

  downloadFileHistory(spaceId, trackingId) {
    return this.call(
      (target) => tBoxClient.file.downloadHistory(spaceId, trackingId, target),
      (target) => tBoxClient.file.downloadHistory(spaceId, trackingId, target),
    );
  }

  downloadDepositBox(boxId, fileNames, isZip) {
    return this.call(
      (target) =>
        tBoxClient.depositBox.download(boxId, fileNames, target, {
          zip: isZip,
        }),
      (target) =>
        tBoxClient.depositBox.download(boxId, fileNames, target, {
          zip: isZip,
        }),
    );
  }

  async call(download, downloadToDisk) {
    if (ENV.APP.isDesktopApp) {
      // First call should use the path retrieved in the constructor.
      // Next calls will ask the path again.
      const path = this.path || (await showSaveDialog(this.name));
      this.path = null;
      if (!path) {
        throw libcryptobox.ErrorCode.Canceled;
      }
      return downloadToDisk(path);
    } else {
      let writer = new FileWriter(this.name, this.size);
      let promise = download(writer);
      promise
        .then(() => {
          writer.close();
        })
        .catch((error) => {
          writer.close(error);
        });
      // NB. Return the toolbox promise, it has the cancel and progress methods.
      return promise;
    }
  }
}

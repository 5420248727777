import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  EventBus,
  NullL10n,
  PDFFindController,
  PDFLinkService,
  PDFScriptingManager,
  PDFViewer,
} from 'pdfjs-dist/web/pdf_viewer.mjs';
import pdfjsLib from 'pdfjs-dist';
import { DateTime } from 'luxon';

export default class PdfjsServiceService extends Service {
  container = document.getElementById('viewerContainer');
  @tracked pdfViewer = null;
  @tracked findController = null;
  @tracked scriptingManager = null;
  @tracked eventBus = null;
  @tracked linkService = null;
  @tracked presentationMode = null;
  @tracked toolbar = null;
  @tracked fileName = '';
  @tracked pdfDocument = null;
  @tracked pdfUrl = '';

  constructor() {
    super(...arguments);
    this.initializeBase();
  }

  @action
  alignToolbarItemPosition() {
    const toolbar = document.getElementById('toolbar');
    const searchButton = document.getElementById('viewFindButton');
    const menuButton = document.getElementById('secondaryToolbarToggle');
    const menuRect = menuButton.getBoundingClientRect();
    const searchRect = searchButton.getBoundingClientRect();

    document.getElementById('findbar').style.bottom =
      toolbar.offsetHeight + 10 + 'px';
    document.getElementById('findbar').style.left = searchRect.left + 'px';
    document.getElementById('secondaryToolbar').style.bottom =
      toolbar.offsetHeight + 10 + 'px';
    document.getElementById('secondaryToolbar').style.left =
      menuRect.left + 'px';

    return searchRect.left > 0 && toolbar.offsetHeight > 0;
  }

  initializeBase() {
    this.eventBus = new EventBus();

    this.linkService = new PDFLinkService({
      eventBus: this.eventBus,
    });

    this.scriptingManager = new PDFScriptingManager({
      eventBus: this.eventBus,
    });

    this.findController = new PDFFindController({
      linkService: this.linkService,
      eventBus: this.eventBus,
    });
  }

  @action
  initialize() {
    this.container = document.querySelector('#viewerContainer');
    this.viewer = document.querySelector('#viewer');

    if (!this.container || !this.viewer) {
      throw new Error('Container or viewer element not found');
    }

    if (this.pdfViewer) {
      this.pdfViewer.cleanup();
      this.pdfViewer = null;
    }

    this.pdfViewer = new PDFViewer({
      container: this.container,
      viewer: this.viewer,
      eventBus: this.eventBus,
      scriptingManager: this.scriptingManager,
      l10n: NullL10n,
      linkService: this.linkService,
      findController: this.findController,
      enableScripting: false,
      enablePrintAutoRotate: true,
      textLayerMode: 1,
      enhanceTextSelection: true,
      renderInteractiveForms: true,
      removePageBorders: true,
      enablePresentationMode: false,
      enableHandToolOnLoad: true,
    });
    this.linkService.setViewer(this.pdfViewer);
    return this.pdfViewer;
  }

  @action
  async loadPdf(url, password = null) {
    try {
      const viewer = this.initialize();
      this.pdfUrl = url;
      const loadingTask = pdfjsLib.getDocument({ url, password });

      const pdfDocument = await loadingTask.promise;
      this.pdfDocument = pdfDocument;
      viewer.setDocument(pdfDocument);
      this.linkService.setDocument(pdfDocument, null);
      this.findController.setDocument(pdfDocument);

      this.eventBus.dispatch('documentloaded', {
        source: this,
        pdfDocument,
      });

      await this.scriptingManager.setDocument(pdfDocument);

      this.pdfViewer.currentScaleValue = 'auto';
    } catch (error) {
      if (error.name === 'PasswordException') {
        document.getElementById('passwordDialog').setAttribute('open', 'true');
        return;
      }
      console.error(error);
      throw new Error(error);
    }
  }

  @action
  async togglePresentationMode() {
    if (this.pdfViewer) {
      try {
        if (!this.pdfViewer.isInPresentationMode) {
          await this.container.requestFullscreen();
          this.eventBus.dispatch('presentationmode');
        } else {
          await document.exitFullscreen();
          this.eventBus.dispatch('exitpresentationmode');
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  @action
  selectTool() {
    this.setCursorTool(0);
  }

  @action
  handTool() {
    this.setCursorTool(1);
  }

  @action
  setSpreadMode(mode) {
    if (!this.pdfViewer) return;

    const buttons = {
      spreadNone: document.getElementById('spreadNone'),
      spreadOdd: document.getElementById('spreadOdd'),
      spreadEven: document.getElementById('spreadEven'),
    };

    Object.values(buttons).forEach((button) => {
      button?.classList.remove('toggled');
      button?.setAttribute('aria-checked', 'false');
    });

    const selectedButton =
      buttons[`spread${ mode.charAt(0).toUpperCase() + mode.slice(1) }`];
    selectedButton?.classList.add('toggled');
    selectedButton?.setAttribute('aria-checked', 'true');

    const spreadModes = { none: 0, odd: 1, even: 2 };
    this.pdfViewer.spreadMode = spreadModes[mode];
  }

  @action
  setScrollMode(mode) {
    if (!this.pdfViewer) return;

    const buttons = {
      scrollPage: document.getElementById('scrollPage'),
      scrollVertical: document.getElementById('scrollVertical'),
      scrollHorizontal: document.getElementById('scrollHorizontal'),
      scrollWrapped: document.getElementById('scrollWrapped'),
    };

    Object.values(buttons).forEach((button) => {
      button?.classList.remove('toggled');
      button?.setAttribute('aria-checked', 'false');
    });

    const selectedButton =
      buttons[`scroll${ mode.charAt(0).toUpperCase() + mode.slice(1) }`];
    selectedButton?.classList.add('toggled');
    selectedButton?.setAttribute('aria-checked', 'true');

    const scrollModes = { page: 3, vertical: 2, horizontal: 1, wrapped: 0 };
    this.pdfViewer.scrollMode = scrollModes[mode];
  }

  @action
  async showDocumentProperties() {
    const doc = this.pdfDocument;
    if (!doc) return;

    const metadatas = await doc.getMetadata();
    const { info } = metadatas;
    const firstPage = await doc.getPage(1);
    const viewport = firstPage.getViewport({ scale: 1 });
    const data = await doc.getData();

    this.documentProperties = {
      fileName: this.fileName || '-',
      fileSize: this.formatFileSize(data.byteLength),
      title: info?.Title || '-',
      author: info?.Author || '-',
      subject: info?.Subject || '-',
      keywords: info?.Keywords || '-',
      creationDate: this.formatDate(info?.CreationDate),
      modificationDate: this.formatDate(info?.ModDate),
      creator: info?.Creator || '-',
      producer: info?.Producer || '-',
      version: `${ info?.PDFFormatVersion || '-' }`,
      pageCount: doc.numPages,
      pageSize: `${ Math.round(viewport.width) } × ${ Math.round(viewport.height) } pixels`,
      linearized: doc.linearized ? 'Yes' : 'No',
    };

    this.updateDialog();
    document.getElementById('documentPropertiesDialog').showModal();
  }

  formatFileSize(bytes) {
    const kb = bytes/1024;
    return kb < 1024
      ? `${ Math.round(kb*100)/100 } KB`
      : `${ Math.round((kb/1024)*100)/100 } MB`;
  }

  formatDate(date) {
    if (!date) return '-';
    const formatted = date
      .slice(2)
      .replace(
        /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})([-+]\d{2})'(\d{2})'$/,
        '$1-$2-$3T$4:$5:$6$7:$8',
      );
    return DateTime.fromISO(formatted).toLocaleString(DateTime.DATETIME_SHORT);
  }

  updateDialog() {
    Object.entries(this.documentProperties).forEach(([key, value]) => {
      const element = document.getElementById(`${ key }Field`);
      if (element) element.textContent = (value || '').toString();
    });
  }
  @action
  setCursorTool(toolMode) {
    if (!this.pdfViewer) return;

    const cursorSelectTool = document.getElementById('cursorSelectTool');
    const cursorHandTool = document.getElementById('cursorHandTool');

    if (toolMode === 1) {
      cursorSelectTool?.classList.remove('toggled');
      cursorHandTool?.classList.add('toggled');
      cursorSelectTool?.setAttribute('aria-checked', 'false');
      cursorHandTool?.setAttribute('aria-checked', 'true');
      this.pdfViewer.containerMousemove = true;
    } else {
      cursorSelectTool?.classList.add('toggled');
      cursorHandTool?.classList.remove('toggled');
      cursorSelectTool?.setAttribute('aria-checked', 'true');
      cursorHandTool?.setAttribute('aria-checked', 'false');
      this.pdfViewer.containerMousemove = false;
    }

    this.eventBus.dispatch('switchcursortool', {
      source: this,
      tool: toolMode,
    });
  }
  willDestroy() {
    super.willDestroy(...arguments);
    if (this.fileName) {
      this.fileName = '';
    }
    if (this.pdfDocument) {
      this.pdfDocument = null;
    }
    if (this.presentationMode) {
      if (this.presentationMode.isActive) {
        this.presentationMode.exit();
      }
      const presentationButton = document.getElementById('presentationMode');
      if (presentationButton) {
        presentationButton.removeEventListener(
          'click',
          this.togglePresentationMode,
        );
      }
    }
    if (this.eventBus) {
      this.eventBus.destroy();
    }

    if (this.pdfViewer) {
      this.pdfViewer.cleanup();
    }
  }
}
